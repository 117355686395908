<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Logarithms</h2>
      <p class="mb-3">
        Logarithms, or logs for short, are effectively the inverse of exponentials. If you are
        trying to isolate a variable in an exponent, you can take a logarithm, whereas if you are
        trying to isolate a variable inside of a log function, you would use an exponent. Let's take
        a look at the form of a logarithmic function by considering the following:
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex content="$\log_3(81)=4$" />
      </p>

      <p class="mb-3">What this expression is telling us is that:</p>

      <p class="pl-6 mb-5">
        <stemble-latex content="$3^4=81$" />
      </p>

      <p class="mb-3">
        In these two expressions, the 3 is known as the base. We would read the first expression as
        "the log with base 3 of 81 is equal to 4". If we were ever trying to evaluate a log function
        such as
        <stemble-latex content="$\log_3(81)$" />, we would have to ask ourselves, "How many times
        would we have to multiply 3 by itself to get 81?".
      </p>

      <p class="mb-3">
        Fortunately, in science, we are typically dealing with base 10 or base e, where e is known
        as Euler's number and is approximately equal to 2.718. Better yet, calculators can easily
        evaluate logarithms with base 10 and base e. As these are the two most common bases for log
        functions, there are special notations for these two bases.
      </p>

      <p class="pl-6 mb-2">
        <stemble-latex
          content="$\text{For base 10:}\hspace{0.5 cm}\log_{10}(\text{x})=\log(\text{x})$"
        />
      </p>

      <p class="pl-6 mb-4">
        <stemble-latex
          content="$\text{For base e:}\hspace{0.7 cm}\log_\text{e}(\text{x})=\ln(\text{x})$"
        />
      </p>

      <p class="mb-3">
        So, a log function with no base indicated implies base 10, while writing ln instead of log
        implies a log with base e! Using this knowledge, evaluate the following log expressions:
      </p>

      <p class="pl-6 mb-2">
        <stemble-latex content="$\text{a) log}_2(64)$" />
      </p>

      <calculation-input
        v-model="inputs.log1"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />

      <p class="pl-6 mb-2">
        <stemble-latex content="$\text{b) log}_4(256)$" />
      </p>

      <calculation-input
        v-model="inputs.log2"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />

      <p class="pl-6 mb-2">
        <stemble-latex content="$\text{c) log(1000)}$" />
      </p>

      <calculation-input
        v-model="inputs.log3"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />

      <p class="pl-6 mb-2">
        <stemble-latex content="$\text{d) ln(20.0855)}$" />
      </p>

      <calculation-input
        v-model="inputs.log4"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        It's important to note that log functions DO NOT have to evaluate to integers. When dealing
        with bases that aren't 10 or e, this isn't a scenario we have to be concerned with, but
        since our calculators can easily evaluate logs of base 10 and base e, we should be able to
        calculate such functions. Try and evaluate the logs below. Express each answer to 3
        significant figures.
      </p>

      <p class="pl-6 mb-2">
        <stemble-latex content="$\text{e) log(}$" />
        <number-value :value="logNum1" />
        <stemble-latex content="$)$" />
      </p>

      <calculation-input
        v-model="inputs.log5"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />

      <p class="pl-6 mb-2">
        <stemble-latex content="$\text{f) log(}$" />
        <number-value :value="logNum2" />
        <stemble-latex content="$)$" />
      </p>

      <calculation-input
        v-model="inputs.log6"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />

      <p class="pl-6 mb-2">
        <stemble-latex content="$\text{g) ln(}$" />
        <number-value :value="lnNum3" />
        <stemble-latex content="$)$" />
      </p>

      <calculation-input
        v-model="inputs.log7"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />

      <p class="pl-6 mb-2">
        <stemble-latex content="$\text{h) ln(}$" />
        <number-value :value="lnNum4" />
        <stemble-latex content="$)$" />
      </p>

      <calculation-input
        v-model="inputs.log8"
        class="pl-6 mb-5"
        :prepend-text="'$\\text{Number:}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question350',
  components: {
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        log1: null,
        log2: null,
        log3: null,
        log4: null,
        log5: null,
        log6: null,
        log7: null,
        log8: null,
      },
    };
  },
  computed: {
    logNum1() {
      return this.taskState.getValueBySymbol('logNum1').content;
    },
    logNum2() {
      return this.taskState.getValueBySymbol('logNum2').content;
    },
    lnNum3() {
      return this.taskState.getValueBySymbol('lnNum3').content;
    },
    lnNum4() {
      return this.taskState.getValueBySymbol('lnNum4').content;
    },
  },
};
</script>
